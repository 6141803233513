import {useSelector} from "react-redux";
import React from "react";

const DesktopBalance = React.memo(function () {
    const id = useSelector(state => state.user.id);
    const userName = useSelector(state => state.user.userName);
    const currencyCode = useSelector(state => state.user.currencyCode);
    const balance = useSelector(state => state.user.balance);

    return  <label>
        <p className="header__user__id">ID {id}</p>
        <p className="header__user__nickname">{userName}</p>
        <p className="header__user__balance">{`${balance.toFixed(2)} ${currencyCode}`}</p>
    </label>
})

export default DesktopBalance;