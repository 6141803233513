import React from 'react';
import {useTranslation} from 'react-i18next';

const Multiplier = ({multiplier, sprite, crashedMultiplier}) => {
    const {t} = useTranslation();
    return <>
        {!crashedMultiplier && multiplier !== 0 && <div className="animation__multiplier">{multiplier}</div>}
        {crashedMultiplier && sprite === 18 &&
            <div className="animation__multiplier animation__multiplier--crashed">
                <div className="animation__multiplier--crashed--result">{t('animation.result')}</div>
                <div className="animation__multiplier--crashed--odd">{crashedMultiplier}</div>
            </div>}
    </>
};

export default Multiplier;
