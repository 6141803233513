import MODAL from "../actions/modal/types";

const initialState = {
    activeModalName: '',
    honestyData: {},
    isMobileMenuOpen: false,
};
const reducer = (state = initialState, action) => {
    switch (action?.type) {

        case MODAL.CLOSE_MODAL:
            return {
                ...state,
                activeModalName: '',
                honestyData: {}
            }

        case MODAL.OPEN_MODAL:
            return {
                ...state,
                activeModalName: action.payload.name,
                honestyData: action.payload.data,
            }

        case MODAL.OPEN_MOBILE_MENU:
            return {
                ...state,
                isMobileMenuOpen: true
            }

        case MODAL.CLOSE_MOBILE_MENU:
            return {
                ...state,
                isMobileMenuOpen: false
            }

        default:
            return state;
    }
};
export default reducer;
