import {useEffect, useRef} from 'react';
import {userToggleDemo} from "../redux/actions/user/actions";
import {useDispatch, useSelector} from "react-redux";

const useGameMode = () => {
    const dispatch = useDispatch();
    const isDemoActive= useSelector(state => state.user.isDemoActive);
    const bets = useSelector(state => state.controller.bets);
    const isDemoRef = useRef(isDemoActive);
    const canChangeMode = useRef(true);

    useEffect(() => {
        canChangeMode.current = bets[1]?.transactionId || bets[2]?.transactionId
    }, [bets]);

    useEffect(() => {
        isDemoRef.current = isDemoActive
    }, [isDemoActive]);

    const toggleGameMode = () => {
        if (canChangeMode.current && !isDemoRef.current) return;
        dispatch(userToggleDemo({isDemoActive: !isDemoRef.current}));
    };

    return {
        toggleGameMode
    };
};

export default useGameMode;
