import {useDispatch} from "react-redux";
import {updateController} from "../../redux/actions/controller/actions";
import RangeInput from "../components/common/RangeInput/RangeInput";
import React from "react";

const BetRange = React.memo(function ({controllerId, minBet, maxBet, amount, disabled}) {
    const dispatch = useDispatch();
    const handleChangeBet = (amount) => {
        dispatch(updateController({
            bettorId: controllerId,
            amount
        }))
    }

    return <RangeInput
        minBet={minBet}
        maxBet={maxBet}
        amount={parseFloat(amount) || 0}
        handleChange={handleChangeBet}
        disabled={disabled && 'controller--disabled'}
    />
})

export default BetRange