import Animation from "./AnimationFrame/Animation";
import MobileFinishedRounds from "./components/mobile/MobileFinishedRounds";
import Controllers from "./Controllers/Controllers";
import LeftSideTableContainer from "./components/Tables/LeftSideTable/LeftSideTableContainer";
import RightSideTable from "./components/Tables/RightSideTable/RightSideTable";

function Game() {
    return (
        <div className="main main__wrapper">
            <div className="main__bets">
                <LeftSideTableContainer/>
            </div>
            <div className="main__game">
                <div className="game game__wrapper">
                    <Animation/>
                    <MobileFinishedRounds/>
                    <Controllers/>
                </div>
            </div>
            <div className="main__history">
                <RightSideTable/>
            </div>
        </div>
    )
}

export default Game