import {useTranslation} from 'react-i18next';

const Tabs = (props) => {
    const {t} = useTranslation();
    const {toggle, selected, tabItems = []} = props;
    return <div className={`table__select table__select--${tabItems.length}`}>
        {
            tabItems?.map((item, index) => {
                return <div
                    className='table__select__item'
                    onClick={() => toggle(index + 1)}
                    key={item + index}>{t(item)}
                </div>
            })
        }
        <span className={`toggle active-${selected}`}></span>
    </div>
}
export default Tabs