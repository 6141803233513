import {useSelector} from "react-redux";
import Body from "./Body/Body";
import {useTranslation} from "react-i18next";
function TermAndConditionsModal() {
    const minBet = useSelector(state => (state.controller.minBet));
    const maxBet = useSelector(state => (state.controller.maxBet));
    const minOdd = useSelector(state => (state.controller.minOdd));
    const maxOdd = useSelector(state => (state.controller.maxOdd));
    const maxWin = useSelector(state => (state.controller.maxWin));
    const currencyCode = useSelector(state => state.user.currencyCode);
    const {t} = useTranslation();
    return <Body>
        <div className="popup__title">{t("modals.rules_modal.title")}</div>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph1")}</div>
        <div className="popup__subtitle">{t("modals.rules_modal.subtitle")}</div>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph2")}</div>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph3")}</div>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph4")}</div>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph5")}</div>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph6")}</div>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph7")}</div>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph8")}</div>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph9")}</div>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph10")}</div>
        <ul className="popup__list">
            <li>{t("modals.rules_modal.list_item1")} {minBet} {currencyCode}</li>
            <li>{t("modals.rules_modal.list_item2")} {maxBet} {currencyCode}</li>
            <li>{t("modals.rules_modal.list_item3")} {minOdd}</li>
            <li>{t("modals.rules_modal.list_item4")} {minOdd}</li>
            <li>{t("modals.rules_modal.list_item5")} {maxOdd}</li>
            <li>{t("modals.rules_modal.list_item6")} {`${maxWin} ${currencyCode}`}</li>
        </ul>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph11")}</div>
        <div className="popup__paragraph">{t("modals.rules_modal.paragraph12")}</div>
    </Body>
}

export default TermAndConditionsModal