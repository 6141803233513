import {useDispatch, useSelector} from "react-redux";
import HonestyModal from "./HonestyModal";
import MobileAllBetsModal from "./MobileAllBetsModal";
import MobileHistoryModal from "./MobileHistoryModal";
import MobileSettingsModal from "./MobileSettingsModal";
import MobileTournamentModal from "./MobileTournamentModal";
import PromoModal from "./PromoModal";
import ProvablyFairnessModal from "./ProvablyFairnessModal";
import TermAndConditionsModal from "./TermAndConditionsModal";
import BaseModal from "./BaseModal";
import ModalHeader from "./Header/ModalHeader";
import React, {useEffect} from "react";
import {closeMobileMenu, closeModal} from "../../../redux/actions/modal/actions";

const CombinedModals = {
    HonestyModal,
    MobileAllBetsModal,
    MobileHistoryModal,
    MobileSettingsModal,
    MobileTournamentModal,
    PromoModal,
    ProvablyFairnessModal,
    TermAndConditionsModal,
};

const Modal = React.memo(function () {
    const {isMobile, isMobileLandscape} = useSelector(state => (state.user));
    const activeModalName = useSelector(state => state.modal.activeModalName);
    const ActiveModal = CombinedModals[activeModalName];
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(closeModal());
        dispatch(closeMobileMenu());
    }, [isMobile, dispatch]);

    if (!ActiveModal) return;
    return <BaseModal>
        {(isMobile && !isMobileLandscape) && <ModalHeader/>}
        <ActiveModal/>
    </BaseModal>
})

export default Modal