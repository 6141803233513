import GAME from "../actions/game/types";

const initialState = {
    gameLoader: true,
    round: {
        bettingTime: null,
        multiplier: null,
        crashedMultiplier: null,
        betList: [],
        state: 'start',
        animation: false,
        betsAccepted: false,
    },
    betHistory: [],
    finishedRounds: [],
    highestMultiplierRounds: [],
    isAnimationActive: true,
    notification: {},
    demoBetList: [],
};
const reducer = (state = initialState, action) => {
    switch (action?.type) {
        case  GAME.SET_LOADER:
            return {
                ...state,
                ...action.payload
            }

        case  GAME.UPDATE:
            return {
                ...state,
                ...action.payload
            }

        case  GAME.ROUND_MULTIPLIER:
            return {
                ...state,
                round: {
                    ...state.round,
                    ...action.payload
                }
            }
        case  GAME.ROUND_BETTING_TIMER:
            return {
                ...state,
                round: {
                    ...state.round,
                    ...action.payload
                }
            }
        case  GAME.ROUND_UPDATE:
            return {
                ...state,
                round: {
                    ...state.round,
                    ...action.payload
                }
            }

        case  GAME.ROUND_PLAYER_LIST:
            return {
                ...state,
                round: {
                    ...state.round,
                    betList: action.payload.betList
                }
            }


        case  GAME.ROUND_DEMO_PLAYER_BET:
            return {
                ...state,
                demoBetList: [
                    ...state.demoBetList,
                    action.payload
                ]
            }

        case  GAME.ROUND_DEMO_PLAYER_RESET_BET_BOX:
            let list = [...state.demoBetList];
            const index = list.findIndex(bet => bet.table_transactionId === action.payload.bettorId && bet.table_roundId === action.payload.roundId);
            if (index !== -1) {
                list = [...list.slice(0, index), ...list.slice(index + 1)]
            }
            return {
                ...state,
                demoBetList: list
            }

        case  GAME.ROUND_DEMO_PLAYER_CASH_OUT:
            const updated = [...state.demoBetList];
            updated.forEach(bet => {
                if (bet.table_roundId === action.payload.roundId && bet.table_transactionId === action.payload.bettorId) {
                    bet.table_odd = action.payload.cashOutValue
                    bet.table_win = action.payload.amount
                }
            })
            return {
                ...state,
                demoBetList: updated
            }

        case  GAME.ROUND_CRASH:
            return {
                ...state,
                round: {
                    ...state.round,
                    crashedMultiplier: action.payload.crashedMultiplier,
                    timestamp: action.payload.timestamp,
                    state: action.payload.state,
                }
            }

        case GAME.TOGGLE_ANIMATION:
            return {
                ...state,
                isAnimationActive: !state.isAnimationActive
            }

        case GAME.NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            }

        case GAME.RESET_NOTIFICATION:
            return {
                ...state,
                notification: {}
            }

        default:
            return state;
    }
};
export default reducer;
