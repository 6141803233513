import {closeModal, openMobileMenu} from '../../../redux/actions/modal/actions';
import {useDispatch} from "react-redux";

function HeaderBurger() {
    const dispatch = useDispatch();
    const handleOpenMobileMenu = () => {
        dispatch(closeModal());
        dispatch(openMobileMenu());
    }
    return <div className="header__mobile" onClick={handleOpenMobileMenu}>
        <i className="header__mobile--settings"/>
    </div>
}

export default HeaderBurger