import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {convertAmountByUserRate, toLocalFullDate, toLocalTime} from "../../../../utilities/utilities";
import Spinner from "../../common/Loaders/Spinner";

const BetHistoryTable = () => {
    const {t} = useTranslation();
    const rate = useSelector(state => state.user.currencyRate)
    const betHistory = useSelector(state => state.game.betHistory);
    const renderRow = (row, index) => {
        return <div className={`table__body--row  table__body--row-roundBets-${row.table_win ? 'green' : 'red'}`}
                    key={index}>
            <div
                className="table__body--row--item  item--date"
                data-full_date={toLocalFullDate(row?.table_date)}
            >
                <span>
                    {toLocalTime(row?.table_date)}
                </span>
            </div>
            <div className={`table__body--row--item `}>
                {convertAmountByUserRate(row?.table_bet, rate)}
            </div>
            <div className={`table__body--row--item item--odd `}>
                {row?.table_odd || '-'}
            </div>
            <div className={`table__body--row--item ${row.table_win ? "item--win" : ''}`}>
                {convertAmountByUserRate(row.table_win, rate)}
            </div>
        </div>
    }

    return (
        <div className="table__content">
            <div className="table__header">
                {
                    ["table_date", 'table_bet', 'table_odd', 'table_win']?.map((header, index) => {
                        return (
                            <div key={index} className="title">
                                {t(`table.${header}`)}
                            </div>
                        )
                    })
                }
            </div>
            <div className="table__body">
                {
                    betHistory?.length ? <div className="table__body--wrapper">
                        {
                            betHistory.map((row, index) => {
                                return renderRow(row, index)
                            })
                        }

                    </div> : <Spinner/>
                }
            </div>

        </div>
    );

};

export default BetHistoryTable;
