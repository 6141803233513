import React from "react";

const HeaderIcon = React.memo(function () {
    return <img
        loading={'lazy'}
        className="header__user__avatar"
        src="/img/common/avatars/user-avatar.webp"
        alt="user avatar"
    />

})

export default HeaderIcon;