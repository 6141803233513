import { useSelector } from "react-redux";
import React, {useEffect, useState} from "react";
import Tabs from "../Tabs/Tabs";
import RoundHistoryTable from "./RoundHistoryTable";
import HighestOddHistoryTable from "./HighestOddHistoryTable";
import TournamentTable from "../TournamentTable/TournamentTable";

const RightSideTable = React.memo(function () {
    const [selectedTabIndex, setSelectedTabIndex] = useState(1);
    const isMobile = useSelector(state => state.user.isMobile);
    const hasTournament = useSelector(state => state.tournament.hasTournament);

    const tabTitles = hasTournament && !isMobile
        ? ['tabs.round_history', 'tabs.round_history_top', 'tabs.tournament']
        : ['tabs.round_history', 'tabs.round_history_top'];

    useEffect(() => {
        if (isMobile && selectedTabIndex === 3) {
            setSelectedTabIndex(1)
        }
    }, [isMobile, selectedTabIndex])

    useEffect(() => {
        if (!hasTournament && selectedTabIndex === 3) {
            setSelectedTabIndex(1);
        }
    }, [hasTournament, selectedTabIndex])

    return (
        <div className="table table__wrapper">
            <Tabs
                toggle={setSelectedTabIndex}
                selected={selectedTabIndex}
                tabItems={tabTitles}
            />
            {
                selectedTabIndex === 1 && <RoundHistoryTable/>
            }
            {
                selectedTabIndex === 2 && <HighestOddHistoryTable/>
            }
            {
                selectedTabIndex === 3 && <TournamentTable/>
            }
        </div>
    );
})

export default RightSideTable;
