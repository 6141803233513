import {useDispatch, useSelector} from "react-redux";
import React from "react";
import Switcher from "../common/Switcher/Switcher";
import {gameToggleAnimation} from "../../../redux/actions/game/actions";
import DropDown from "../common/DropDown/DropDown";
import {useTranslation} from "react-i18next";
import useGameMode from "../../../hooks/useGameMode";

const DesktopMenu = React.memo(function () {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isDemoActive = useSelector(state => state.user.isDemoActive);
    const isLogin = useSelector(state => state.user.isLogin);
    const isAnimationActive = useSelector(state => state.game.isAnimationActive)
    const { toggleGameMode } = useGameMode();

    const handleGameModeChange = () => {
        if (!isLogin) return;
        toggleGameMode()
    }

    return <DropDown items={[
        {
            component: <><label>{t(`dropdown.animation`)}</label><Switcher
                onToggle={() => dispatch(gameToggleAnimation())} isActive={isAnimationActive}/></>
        },
        {
            component: <><label>{t(`dropdown.demo`)}</label><Switcher
                onToggle={handleGameModeChange}
                isActive={isDemoActive}
            /></>
        },
    ]}/>
})

export default DesktopMenu;