export const BackgroundElements = [
    {name: 'dog.webp', className: 'moving-z-left', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'cow.webp', className: 'moving-z', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'human.webp', className: 'moving-y', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'nut.webp', className: 'moving-z-left', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'nut2.webp', className: 'moving-z', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'planet3.webp', className: 'moving-z', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'robot.webp', className: 'moving-z-left', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'smile.webp', className: 'moving-z', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'smilePlanet.webp', className: 'moving-y', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
    {name: 'rocket.webp', className: 'moving-y', size: Math.floor(Math.random() * (20 - 5 + 1)) + 5},
]
