import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {convertAmountByUserRate} from "../../../../utilities/utilities";
import Spinner from "../../common/Loaders/Spinner";

const BetListTable = () => {
    const {t} = useTranslation();
    const {id, betList} = useSelector(state => state.game.round);
    const demoBetList = useSelector(state => state.game.demoBetList);
    const {isDemoActive, currencyRate: rate} = useSelector(state => state.user);
    const bets =  isDemoActive ? demoBetList.filter(bet => bet.table_roundId === id) : betList;
    const renderRow = (row, index) => {
        return <div className={`table__body--row  table__body--row-betHistory-${row.table_win ? 'green' : 'red'}`}
                    key={index}>
            <div className="table__body--row--item">
                {row?.table_name}
            </div>
            <div className={`table__body--row--item `}>
                {convertAmountByUserRate(row?.table_bet, rate)}
            </div>
            <div className={`table__body--row--item item--odd `}>
                {row?.table_odd || '-'}
            </div>
            <div className={`table__body--row--item ${row.table_win ? "item--win" : ''}`}>
                {convertAmountByUserRate(row.table_win, rate)}
            </div>
        </div>
    }


    return (
        <div className="table__content">
            <div className="table__header">
                {
                    ["table_name", 'table_bet', 'table_odd', 'table_win']?.map((header, index) => {
                        return (
                            <div key={index} className="title">
                                {t(`table.${header}`)}
                            </div>
                        )
                    })
                }
            </div>
            <div className="table__body">
                {
                    bets?.length ? <div className="table__body--wrapper">
                        {
                            bets.map((row, index) => {
                                return renderRow(row, index)
                            })
                        }
                    </div> : <Spinner/>
                }
            </div>

        </div>
    );

};

export default BetListTable;
