import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import App from './App';
import setupSocket, {socketMiddleware} from "./sockets";
import {I18nextProvider} from 'react-i18next';
import initI18n from './i18n';
import {userIsMobile} from "./redux/actions/user/actions";
import {detectMobile} from "./utilities/utilities";
import rootReducer from "./redux/reducers";
import { configureStore, Tuple} from '@reduxjs/toolkit';

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.REACT_APP_ENV !== 'production',
    middleware: () => new Tuple(socketMiddleware),
    enhancers: (getDefaultEnhancers) =>
        getDefaultEnhancers({
            autoBatch: false,
        })
});

const i18n = initI18n('en');
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

setupSocket(store.dispatch).catch(err => console.log(err));
store.dispatch(userIsMobile(detectMobile()));

root.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <App changeLanguage={(lang) => i18n.changeLanguage(lang)} />
        </Provider>
    </I18nextProvider>
);


export default store;
