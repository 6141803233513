import NumberStepper from "../components/common/NumberStepper/NumberStepper";
import {useDispatch} from "react-redux";
import {updateController} from "../../redux/actions/controller/actions";
import React from "react";

const BetStepper  = React.memo(function({controllerId, minBet, maxBet, amount, disabled}) {
    const dispatch = useDispatch();
    const handleChangeBet = (amount) => {
        dispatch(updateController({
            bettorId: controllerId,
            amount
        }))
    }
    return <NumberStepper
        minValue={minBet}
        maxValue={maxBet}
        inputValue={amount}
        step={minBet}
        handleChange={handleChangeBet}
        hasDoubleButton={true}
        disabled={disabled && 'controller--disabled'}
    />
})

export default BetStepper