import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const ButtonsBar = React.memo(({handleClick, currentBet, maxBet, disabled}) => {
    const {balance, demoBalance, isDemoActive} = useSelector(state => (state.user));
    const fixedButtons = useSelector(state => (state.controller.fixedButtons));
    const [selectedButtonKey, setSelectedButtonKey] = useState(null);
    const {t} = useTranslation();
    const handleBtnClick = ({key, value}) => {
        if(value === 'all_in') {
            handleAllIn()
        } else {
            let result;
            if (key === selectedButtonKey) {
                const maxBetFloat = parseFloat(maxBet)
                result = currentBet < value ? value : parseFloat(currentBet) + value > maxBetFloat ? maxBetFloat : parseFloat(currentBet) + value;
            } else {
                result = value
            }
            setSelectedButtonKey(key);
            handleClick(result.toFixed(2));
        }
    }

    const handleAllIn = () => {
        let allInAmount = isDemoActive ? demoBalance : balance;
        let result = allInAmount > maxBet ? maxBet : allInAmount;
        handleClick(result)
    }

    return <div className={`controlItem__left--btn ${disabled}`}>
        {
            fixedButtons && Object.values(fixedButtons).map(((value, index) => {
                const name =( value === 'all_in') ? t('control.all_in') : value;
                return <button
                    key={`${value}${index}${Math.random()}`}
                    onClick={() => handleBtnClick({key: index, value})}
                    className="controlItem__left--btn--item"
                > {name} </button>
            }))
        }
    </div>
}, (prevProps, nextProps) => {
    return (prevProps.currentBet === nextProps.currentBet) && (prevProps.disabled === nextProps.disabled)
})

export default ButtonsBar
