import {useTranslation} from "react-i18next";
import {convertAmountByUserRate} from "../../../utilities/utilities";
import {useDispatch, useSelector} from "react-redux";
import useDebounce from "../../../hooks/DebounceClick";
import {cashOut, resetController} from "../../../redux/actions/controller/actions";
import {gameRoundDemoPlayerCashOut} from "../../../redux/actions/game/actions";
import {userUpdateBalance} from "../../../redux/actions/user/actions";

function CashOutBtn({data}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const currencyRate = useSelector(state => state.user.currencyRate);
    const debouncedCashOut = useDebounce(handleCashOut);

    function handleCashOut() {
        if (data?.isDemoActive) {
            const winAmount = (parseFloat(data.amount) * parseFloat(data.multiplier)).toFixed(2)
            dispatch(resetController({
                bettorId: data.bettorId,
                controller: {}
            }));
            dispatch(gameRoundDemoPlayerCashOut({
                roundId: data.roundId,
                bettorId: data.bettorId,
                cashOutValue: data.multiplier,
                amount: winAmount
            }))
            dispatch(userUpdateBalance({balance: parseFloat(data.balance) + parseFloat(winAmount)}))
        } else {
            dispatch(cashOut({
                bettorId: data.bettorId,
                cashOutValue: data.multiplier,
            }))
        }
    }

    return <div className="controlItem__right__bet">
        <button className={`controlItem__right__bet--btn green`} onClick={() => debouncedCashOut()}>
            <span className="controlItem__right__bet--btn--text">{t("control.cashOut_btn")}</span>
            <div
                className="controlItem__right__bet--btn--cashOut-amount">{(convertAmountByUserRate(data.amount, currencyRate) * data.multiplier).toFixed(2)}
            </div>
        </button>
    </div>
}

export default CashOutBtn;