import {useSelector} from "react-redux";
import React from "react";

const MobileFinishedRounds = React.memo(function () {
    const finishedRounds = useSelector(state => state.game.finishedRounds);
    const {isMobile, isMobileLandscape} = useSelector(state => state.user);
    return (
        isMobile && !isMobileLandscape && <div className="mobile__history">
            <div className="mobile__history__wrapper">
                {finishedRounds?.map((round, index) => {
                    return <div
                        key={index}
                        className="mobile__history--odd">
                        {`x${round?.table_odd}`}
                    </div>
                })}
            </div>
        </div>
    )
})

export default MobileFinishedRounds