export const detectMobile = () => {
    return {
        isMobile: window.innerWidth < 641,
        isMobileLandscape:  (window.innerHeight < window.innerWidth &&  window.innerHeight < 641)
    }
};
export const convertAmountByUserRate = (amount, rate) => {
    let val = (amount / rate).toFixed(2);
    return val === '0.00' ? '-' : val
};

export const toLocalFullDate = (timeStamp, options= {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
}) => {
    return `${new Date(timeStamp).toLocaleDateString('en-GB')} ${new Date(timeStamp).toLocaleString('en-GB', options)}`
}

export const toLocalTime = (timeStamp, options= {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
}) => {
    return `${new Date(timeStamp).toLocaleString('en-GB', options)}`
}

export const toLocalDate = (timeStamp, options= {
    month: 'numeric',
    day: 'numeric',
    // year: 'numeric'
}) => {
    return `${new Date(timeStamp).toLocaleDateString('en-GB',options)}`
}