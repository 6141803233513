import MobileBalance from "./MobileBalance";
import HeaderLogo from "../common/HeaderLogo/HeaderLogo";
import HeaderBurger from "./HeaderBurger";

function HeaderMobile() {
    return <div className="header header__wrapper">
        <MobileBalance/>
        <HeaderLogo/>
        <HeaderBurger/>
    </div>
}

export default HeaderMobile;