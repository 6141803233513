// eslint-disable
import {useDispatch, useSelector} from "react-redux";
import Bet from "./Bet";
import CashOutBtn from "./CashOutBtn";
import AcceptedButton from "./AcceptedButton";
import CancelBtn from "./CancelBtn";
import {useEffect, useRef} from "react";
import {bet, betAccepted, cashOut, resetController} from "../../../redux/actions/controller/actions";
import {
    gameRoundDemoPlayerBet, gameRoundDemoPlayerResetBetBox,
    gameRoundDemoPlayerCashOut,
} from "../../../redux/actions/game/actions";
import {userUpdateBalance} from "../../../redux/actions/user/actions";

function BetButton(controller) {
    const isDemoActive = useSelector(state => state.user.isDemoActive)
    const balance = useSelector(state => state.user.balance);
    const betBox = useSelector(state => state.controller.bets[controller.controllerId]);
    const currentRound = useSelector(state => state.game.round);
    const dispatch = useDispatch();
    const prevMultiplierRef = useRef();

    useEffect(() => {
        if (currentRound.state === 'betting' && controller.autoBet && !betBox.transactionId) {
            if (isDemoActive) {
                dispatch(betAccepted({
                    bettorId: controller.controllerId,
                    roundId: controller.betRoundId,
                    amount: controller.amount,
                    transactionId: controller.controllerId,
                }))
                dispatch(gameRoundDemoPlayerBet({
                    table_roundId: controller.betRoundId,
                    table_transactionId: controller.controllerId,
                    table_name: "D***o",
                    table_bet: controller.amount,
                    table_odd: null,
                    table_win: null,
                }))
                dispatch(userUpdateBalance({balance: parseFloat(balance) - parseFloat(controller.amount)}))
            } else {
                dispatch(bet({
                    bettorId: controller.controllerId,
                    amount: controller.amount,
                    isAutoBet: controller.autoBet,
                }));
            }
        }
        if (currentRound.state === 'crashed' && isDemoActive) {
            dispatch(gameRoundDemoPlayerResetBetBox({
                roundId: currentRound.id,
                bettorId: controller.controllerId
            }))
            if (betBox.roundId === currentRound.id) {
                dispatch(resetController({
                    bettorId: controller.controllerId,
                    controller: {}
                }));
                dispatch(gameRoundDemoPlayerCashOut({
                    roundId: currentRound.id,
                    bettorId: controller.controllerId,
                    cashOutValue: 0,
                    amount: 0
                }))
            }
        }
    }, [currentRound.state]);

    useEffect(() => {
        if (!controller.autoCashOut || !betBox.transactionId) return
        if (parseFloat(prevMultiplierRef.current) < controller.autoCashOutOdd && parseFloat(currentRound.multiplier) >= controller.autoCashOutOdd) {
            if (isDemoActive) {
                const winAmount = (parseFloat(controller.amount) * parseFloat(currentRound.multiplier)).toFixed(2);
                dispatch(userUpdateBalance({balance: parseFloat(balance) + parseFloat(winAmount)}))
                dispatch(resetController({
                    bettorId: controller.controllerId,
                    controller: {},
                }));
                dispatch(gameRoundDemoPlayerCashOut({
                    roundId: currentRound.id,
                    bettorId: controller.controllerId,
                    cashOutValue: currentRound.multiplier,
                    amount: winAmount
                }))

            } else {
                dispatch(cashOut({
                    bettorId: controller.controllerId,
                    cashOutValue: controller.autoCashOutOdd,
                    autoCashOut: controller.autoCashOut,
                }))
            }
        }
        prevMultiplierRef.current = currentRound.multiplier;

    }, [currentRound.multiplier]);


    const renderButton = () => {
        const {transactionId, roundId, amount} = betBox || {};
        const {state, multiplier} = currentRound || {};

        const commonData = {
            bettorId: controller.controllerId,
            roundId,
            transactionId,
            isDemoActive,
            amount,
            balance
        };

        if (transactionId) {
            if (roundId !== currentRound.id) return <CancelBtn data={commonData}/>;
            switch (state) {
                case 'start':
                case 'betting':
                    if (!currentRound?.betsAccepted) return <CancelBtn data={commonData}/>;
                    if (currentRound?.betsAccepted) return <AcceptedButton/>;
                    break;
                case 'playing':
                    return <CashOutBtn data={{
                        bettorId: controller.controllerId,
                        roundId: currentRound.id,
                        isDemoActive,
                        amount,
                        multiplier,
                        balance
                    }}/>
                default:
                    break;
            }
        }
        return <Bet data={{
            bettorId: controller.controllerId,
            amount: controller.amount,
            isAutoBet: controller.autoBet,
            betRoundId: controller.betRoundId,
            next: currentRound.multiplier !== 0,
            isDemoActive,
            balance
        }}/>;
    };
    return (renderButton())
}

export default BetButton;
