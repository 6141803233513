import React from "react";
import HeaderTournament from "./HeaderTournament";
import HeaderIcon from "./HeaderIcon";
import DesktopBalance from "./DesktopBalance";
import DesktopMenu from "./DesktopMenu";

const HeaderUserInfo = React.memo(function () {
    return <div className="header__user">
        <div className="header__user__wrapper">
            <HeaderTournament />
            <HeaderIcon />
            <DesktopBalance />
        </div>
        <DesktopMenu />
    </div>
})

export default HeaderUserInfo;