import {useSelector} from "react-redux";
import Body from "./Body/Body";
import LeftSideTableContainer from "../Tables/LeftSideTable/LeftSideTableContainer";

function MobileAllBetsModal() {
    const isMobile = useSelector(state => state.user.isMobile);
    return (isMobile && <Body>
        <LeftSideTableContainer />
    </Body>)
}

export default MobileAllBetsModal