import { useTranslation } from 'react-i18next';
function AcceptedButton() {
    const {t} = useTranslation();
    return <div className="controlItem__right__bet">
        <button className="controlItem__right__bet--btn" disabled={true}>
                    <span className="controlItem__right__bet--btn--text">
                         {t("control.bet_accepted")}
                    </span>
        </button>
    </div>
}

export default AcceptedButton;
