const types = {
    EVENT: 'gameEvent',
    NOTIFICATION: 'gameSendNotification',
    UPDATE: 'gameUpdateState',
    ROUND_PLAYER_LIST: 'gameRoundSetPlayerList',
    ROUND_DEMO_PLAYER_BET: 'gameRoundDemoPlayerBet',
    ROUND_DEMO_PLAYER_RESET_BET_BOX: 'gameRoundDemoPlayerResetBetBox',
    ROUND_DEMO_PLAYER_CASH_OUT: 'gameRoundDemoPlayerCashOut',
    ROUND_UPDATE: 'gameRoundUpdateState',
    ROUND_BETTING_TIMER: 'gameRoundSetBettingTime',
    ROUND_MULTIPLIER: 'gameRoundSetMultiplier',
    ROUND_CRASH: 'gameRoundCrash',
    SET_LOADER: 'setLoader',// only client side,
    TOGGLE_ANIMATION: 'gameToggleAnimation',// only client side
    RESET_NOTIFICATION: 'gameResetNotification' //only client side
};
export default types
