import CONTROLLER from "../actions/controller/types";
import UserSocket from "../../sockets/userSocket";
import GAME from "../actions/game/types";

const initialState = {
    bets: {
        1: {
            roundId: null,
            transactionId: null,
            waiting: false,
            amount: 0
        },
        2: {
            roundId: null,
            transactionId: null,
            waiting: false,
            amount: 0
        },
    },
    minOdd: 0,
    maxOdd: 0,
    minBet: 0,
    maxBet: 0,
    maxWin: 0,
    fixedButtons: null,
    defaultBet: 0,
    boxes: {
        1: {
            bettorId: 1,
            isActive: true,
            amount: 0,
            autoCashOutOdd: 0,
            autoBet: false,
            autoCashOut: false,
        },
        2: {
            bettorId: 2,
            isActive: false,
            amount: 0,
            autoCashOutOdd: 0,
            autoBet: false,
            autoCashOut: false,
        }
    },
    betRoundId: null
};
const reducer = (state = initialState, action) => {
    const socket = UserSocket.socket

    switch (action?.type) {

        case CONTROLLER.SET_INITIAL_DATA:
            return {
                ...state,
                ...initialState,
                ...action.payload
            }

        case CONTROLLER.UPDATE:
            return {
                ...state,
                boxes: {
                    ...state.boxes,
                    [action.payload.bettorId]: {
                        ...state.boxes[action.payload.bettorId],
                        ...action.payload
                    }
                }
            }

        case CONTROLLER.BET:
            socket.emit(GAME.EVENT, {action: action.type, payload: action.payload})
            return state;

        case CONTROLLER.GET_ROUND_ID:
            socket.emit(GAME.EVENT, {action: action.type, payload: action.payload})
            return state;

        case CONTROLLER.GET_ROUND_ID_SUCCESS:
            return {
                ...state,
                betRoundId:action.payload.roundId
            };

        case CONTROLLER.BET_ACCEPTED:
            return {
                ...state,
                bets: {
                    ...state.bets,
                    [action.payload.bettorId]: {
                        ...state.bets[action.payload.bettorId],
                        ...action.payload
                    }
                }
            }

        case CONTROLLER.CANCEL_BET:
            socket.emit(GAME.EVENT, {action: action.type, payload: action.payload})
            return state

        case CONTROLLER.CASH_OUT:
            socket.emit(GAME.EVENT, {action: action.type, payload: action.payload})
            return state

        case CONTROLLER.RESET:
            return {
                ...state,
                bets: {
                    ...state.bets,
                    [action.payload.bettorId]: {},
                },
                boxes: {
                    ...state.boxes,
                    [action.payload.bettorId]: {
                        ...state.boxes[action.payload.bettorId],
                        ...action.payload.controller
                    },
                }
            }

        default:
            return state;
    }
};
export default reducer;
