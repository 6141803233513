import CONTROLLER from "./types";
export const setInitialData = (payload) => {
    return {
        type: CONTROLLER.SET_INITIAL_DATA,
        payload
    }
};

export const updateController = (payload) => {
    return {
        type: CONTROLLER.UPDATE,
        payload
    }
};
export const bet = (payload) => {
    return {
        type: CONTROLLER.BET,
        payload
    }
};

export const betAccepted = (payload) => {
    return {
        type: CONTROLLER.BET_ACCEPTED,
        payload
    }
};

export const cancelBet = (payload) => {
    return {
        type: CONTROLLER.CANCEL_BET,
        payload
    }
};
export const cashOut = (payload) => {
    return {
        type: CONTROLLER.CASH_OUT,
        payload
    }
};
export const resetController = (payload) => {
    return {
        type: CONTROLLER.RESET,
        payload
    }
};

export const getRoundId = (payload) => {
    return {
        type: CONTROLLER.GET_ROUND_ID,
        payload
    }
};
export const getRoundIdSuccess = (payload) => {
    return {
        type: CONTROLLER.GET_ROUND_ID_SUCCESS,
        payload
    }
};