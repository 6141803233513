import Controller from "./Controller";
import { useSelector} from "react-redux";
import NewControllerBox from "../components/common/NewControllerBox/NewControllerBox";

function Controllers() {
    const isSecondControllerActive = useSelector(state => state.controller.boxes[2].isActive);
    return (
        <div className="control control__wrapper">
            <div className="control__controlItems__wrapper">
                <Controller id={1} />
                {isSecondControllerActive && <Controller id={2}/>}
            </div>
            <NewControllerBox isActive={!isSecondControllerActive}  />
        </div>
    )
}

export default Controllers
