import React from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useGameMode from "../../../hooks/useGameMode";

const DemoBtn = React.memo(function () {
    const {t} = useTranslation();
    const isDemoActive = useSelector(state => state.user.isDemoActive);
    const isLogin = useSelector(state => state.user.isLogin);
    const { toggleGameMode } = useGameMode();
    const changeGameMode = () => {
        if (!isLogin) {
            window.parent.postMessage({action: 'openLoginModal'}, '*')
            return;
        }
        toggleGameMode(isLogin);
    }

    return (
        isDemoActive && <div
            className={`animation__real-game-btn`}
            onClick={() => changeGameMode()}
        >
            <span className="animation__real-game-btn--text">{t('animation.realButton')}</span>
            <span className="animation__real-game-btn--big"></span>
        </div>

    )
})

export default DemoBtn