import {useSelector} from "react-redux";
import BetStepper from "./BetStepper";
import BetRange from "./BetRange";
import FixedBetButtons from "./FixedBetButtons";
import AutoCashOutStepper from "./AutoCashOutStepper";
import AutoBet from "./AutoBet";
import React from "react";
import CloseController from "./CloseController";
import BetButton from "./BetButton";

const Controller = React.memo(function ({id}) {
    const betRoundId = useSelector(state => (state.controller.betRoundId));
    const minBet = useSelector(state => (state.controller.minBet));
    const amount = useSelector(state => (state.controller.boxes[id].amount));
    const autoCashOutOdd = useSelector(state => (state.controller.boxes[id].autoCashOutOdd));
    const autoCashOut = useSelector(state => (state.controller.boxes[id].autoCashOut));
    const transactionId = useSelector(state => (state.controller.bets[id].transactionId));
    const autoBet = useSelector(state => (state.controller.boxes[id].autoBet));
    const isActive = useSelector(state => (state.controller.boxes[id].isActive));
    const maxBet = useSelector(state => (state.controller.maxBet));
    const minOdd = useSelector(state => (state.controller.minOdd));
    const maxOdd = useSelector(state => (state.controller.maxOdd));

    return (
        <div className="controlItem controlItem__wrapper">
            {id === 2 && !transactionId && <CloseController controllerId={id} isActive={isActive}/>}

            <div className="controlItem__left">
                <BetStepper
                    controllerId={id}
                    minBet={minBet}
                    maxBet={maxBet}
                    amount={amount}
                    disabled={transactionId}
                />
                <BetRange
                    controllerId={id}
                    minBet={minBet}
                    maxBet={maxBet}
                    amount={amount}
                    disabled={transactionId}
                />
                <FixedBetButtons
                    controllerId={id}
                    minBet={minBet}
                    maxBet={maxBet}
                    amount={amount}
                    disabled={transactionId}
                />
                <AutoBet
                    controllerId={id}
                    autoBet={autoBet}
                />
            </div>
            <div className="controlItem__right">
                <BetButton
                    controllerId={id}
                    autoBet={autoBet}
                    amount={amount}
                    autoCashOut={autoCashOut}
                    autoCashOutOdd={autoCashOutOdd}
                    betRoundId={betRoundId}
                />
                <AutoCashOutStepper
                    controllerId={id}
                    minOdd={minOdd}
                    maxOdd={maxOdd}
                    autoCashOut={autoCashOut}
                    cashOutOdd={autoCashOutOdd}
                    disabled={transactionId}
                />
            </div>
        </div>
    )
})

export default Controller