// eslint-disable
import { useSelector } from "react-redux";
import React, {useEffect, useState} from "react";
import Tabs from "../Tabs/Tabs";
import PromoModal from "../../Modal/PromoModal";
import LeaderBoardTable from "./LeaderBoardTable";
import {toLocalDate} from "../../../../utilities/utilities";

const  TournamentTable = React.memo(function () {
    const isMobile = useSelector(state => state.user.isMobile);
    const {leaderBoard, prevTournament, state} = useSelector(state => state.tournament);
    const [selectedTabIndex, setSelectedTabIndex] = useState(1);
    const initialTitles = isMobile ? [`tabs.${state}`,'tabs.previous','tabs.information'] : [`tabs.${state}`,'tabs.previous'];
    const [tabTitles, setTitles] = useState(initialTitles)
    const tableData = selectedTabIndex === 1 ? leaderBoard : prevTournament?.leaderBoard;

    useEffect(() => {
        if (selectedTabIndex === 2 && prevTournament?.endDate) {
            setTitles(()=> {
                initialTitles[1] = `${toLocalDate(prevTournament?.startDate)} - ${toLocalDate(prevTournament?.endDate)}`
            return initialTitles
            })
        } else {
            setTitles(initialTitles)
        }
    }, [selectedTabIndex, isMobile, prevTournament?.endDate]);

    return (
        <div className={isMobile ? 'table table__wrapper': 'sub-table'}>
            <Tabs
                toggle={setSelectedTabIndex}
                selected={selectedTabIndex}
                tabItems={tabTitles}
            />
            {
                <LeaderBoardTable leaderBoard={tableData}/>
            }
            {
                isMobile && selectedTabIndex === 3 && <PromoModal />
            }
        </div>
    );
})

export default TournamentTable;
