import './App.scss';
import LoaderGlobal from "./game/components/common/Loaders/LoaderGlobal";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import Game from "./game/Game";
import Modal from "./game/components/Modal/Modal";
import Header from "./game/Header/Header";
import {userIsMobile} from "./redux/actions/user/actions";
import {detectMobile} from "./utilities/utilities";
import useDebounce from "./hooks/DebounceClick";

const App = ({changeLanguage}) => {
    const id = useSelector(state => state.user.id);
    const lang = useSelector(state => state.user.lang);
    const isMobile = useSelector(state => state.user.isMobile);
    const {gameLoader, notification} = useSelector(state => state.game);
    const dispatch = useDispatch();
    const debouncedDetectDevice = useDebounce(detectDevice, 300);

    useEffect(() => {
        if (lang) {
            changeLanguage(lang)
        }
    }, [lang, changeLanguage]);


    useEffect(() => {
        window.addEventListener('resize', debouncedDetectDevice);
        return () => {
            window.removeEventListener('resize', debouncedDetectDevice);
        };
    }, [debouncedDetectDevice]);

    function detectDevice() {
        const data = detectMobile();
        dispatch(userIsMobile(data));
    }

    return <div className="app">
        <LoaderGlobal
            isActive={gameLoader}
            notifier={notification}
        />
        {
            (!gameLoader && id) && <div className='app__wrapper'>
                <div className={`gameScreen gameScreen__wrapper`}>
                    <Header isMobile={isMobile}/>
                    <Game/>
                    <Modal/>
                </div>
            </div>
        }
    </div>

}
export default App;