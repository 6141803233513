import {useDispatch, useSelector} from "react-redux";
import Body from "./Body/Body";
import Switcher from "../common/Switcher/Switcher";
import {gameToggleAnimation} from "../../../redux/actions/game/actions";
import {useTranslation} from "react-i18next";
import React from "react";
import useGameMode from "../../../hooks/useGameMode";

const MobileSettingsModal = React.memo(function () {
    const isDemoActive = useSelector(state => (state.user.isDemoActive));
    const isLogin = useSelector(state => (state.user.isLogin));
    const isAnimationActive = useSelector(state => (state.game.isAnimationActive));
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const { toggleGameMode } = useGameMode();

    const handleGameModeChange = () => {
        if (!isLogin) return;
        toggleGameMode()
    }

    return<Body>
        <ul className="popup__mobile__settings">
            <li className="popup__mobile__settings--item">
                <label>{t("dropdown.animation")}</label>
                <Switcher onToggle={() => dispatch(gameToggleAnimation())} isActive={isAnimationActive}/>
            </li>
            <li className="popup__mobile__settings--item">
                <label>{t("dropdown.demo")}</label>
                <Switcher onToggle={handleGameModeChange} isActive={isDemoActive}/>
            </li>
        </ul>
    </Body>
})

export default MobileSettingsModal