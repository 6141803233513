import React from 'react';

const NumberStepper = React.memo((props) => {
    const {
        minValue,
        maxValue,
        step,
        inputValue,
        hasDoubleButton,
        handleChange,
        disabled
    } = props;
    const calculateNewAmount = (type) => {
        let currentValue = parseFloat(inputValue);
        let resultAmount;
        switch (type) {
            case 'double':
                resultAmount = currentValue * 2 < parseFloat(maxValue) ? currentValue * 2 : parseFloat(maxValue);
                break;
            case 'plus':
                resultAmount = currentValue + parseFloat(step) < parseFloat(maxValue) ? currentValue + parseFloat(step) : parseFloat(maxValue);
                break;
            case 'minus':
                resultAmount = currentValue - parseFloat(step) > parseFloat(minValue) ? currentValue - parseFloat(step) : parseFloat(minValue);
                break;
            default:
                resultAmount = currentValue;
                break;
        }
        return handleChange(resultAmount.toFixed(2));
    };

    const changeInputValue = (e) => {
        const value = e.target.value;
        const regex = /^(\d+(\.\d{0,2})?)?$/;
        const regexPattern = /^(0\.00|0[1-9]|00)$/;
        if (!regex.test(value)) return;
        let resultValue = regexPattern.test(value) ? parseFloat(minValue) : parseFloat(value) >  parseFloat(maxValue) ?  parseFloat(maxValue) : value;
        handleChange(resultValue);
    };

    const isButtonDisabled = (value) => {
        return parseFloat(inputValue) === value;
    };

    const setMinValue = () => {
        if (!inputValue || inputValue < parseFloat(minValue)) {
            handleChange(parseFloat(minValue).toFixed(2))
        } else {
            handleChange((parseFloat(inputValue).toFixed(2)))
        }
    };
    return <div className={`controlItem__left__amount ${disabled}`}>
        <div className="controlItem__left__amount--margin">
            <button
                className="controlItem__left__amount--btn"
                onClick={() => calculateNewAmount('minus')}
                disabled={isButtonDisabled(minValue)}>
            </button>
            <input
                className="controlItem__left__amount--input"
                max={maxValue}
                value={inputValue}
                onChange={changeInputValue}
                onBlur={setMinValue}
                placeholder={`${minValue}-${maxValue}`}
            />
            <button
                className="controlItem__left__amount--btn plus"
                onClick={() => calculateNewAmount('plus')}
                disabled={isButtonDisabled(maxValue)}>
            </button>
        </div>
        {
            hasDoubleButton &&
            <button
                className="controlItem__left__amount--double"
                onClick={() => calculateNewAmount('double')}
                disabled={isButtonDisabled(maxValue)}
            >
                x2
            </button>
        }
    </div>
}, (prevProps, nextProps) => {
    return (prevProps.inputValue === nextProps.inputValue) && (prevProps.disabled === nextProps.disabled)
})

export default NumberStepper