import MODAL from "./types";
export const closeModal = () => {
    return {
        type: MODAL.CLOSE_MODAL
    }
};
export const openModal = (payload) => {
    return {
        type: MODAL.OPEN_MODAL,
        payload
    }
};

export const openMobileMenu = () => {
    return {
        type: MODAL.OPEN_MOBILE_MENU
    }
};
export const closeMobileMenu = () => {
    return {
        type: MODAL.CLOSE_MOBILE_MENU
    }
};