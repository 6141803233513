import CloseComponent from "../common/CloseComponent/CloseComponent";
import {useDispatch} from "react-redux";
import {closeModal} from '../../../redux/actions/modal/actions'

function BaseModal({children}) {
    const dispatch = useDispatch();
    return <div className="popup popup--opened">
        <div className="popup__overlay" onClick={() => dispatch(closeModal())}></div>
        <div className="popup__wrapper">
            <CloseComponent handleClose={() => dispatch(closeModal())}/>
            <div className="popup__content">
                {children}
            </div>
        </div>
    </div>


}

export default BaseModal;