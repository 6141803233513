import { combineReducers } from 'redux';
import userReducer from './user';
import gameReducer from './game';
import tournamentReducer from './tournament';
import modalReducer from './modal';
import controllerReducer from './controller';

const rootReducer = combineReducers({
    user: userReducer,
    controller: controllerReducer,
    game: gameReducer,
    modal: modalReducer,
    tournament: tournamentReducer,
});
export default rootReducer;
