import USER from "./types";
export const userIsMobile = (payload) => {
    return {
        type: USER.IS_MOBILE,
        payload
    }
};
export const userSetAccountData = (payload) => {
    return {
        type: USER.SET_ACCOUNT_DATA,
        payload
    }
};
export const userUpdateData = (payload) => {
    return {
        type: USER.UPDATE_DATA,
        payload
    }
};
export const userGetBalance = (payload) => {
    return {
        type: USER.GET_BALANCE,
        payload
    }
};


export const userDisconnectSocket = () => {
    return {
        type: USER.DISCONNECT_SOCKET
    }
};
export const userToggleDemo = (payload) => {
    return {
        type: USER.TOGGLE_DEMO,
        payload
    };
};
export const userUpdateBalance = (payload) => {
    return {
        type: USER.UPDATE_DEMO_BALANCE,
        payload
    };
};
