import Tabs from "../Tabs/Tabs";
import React, {useState} from "react";
import BetListTable from "./BetListTable";
import BetHistoryTable from "./BetHistoryTable";

const LeftSideTableContainer = React.memo(function () {
    const [selectedTabIndex, setSelectedTabIndex] = useState(1);
    const handleTabChange = (newTabIndex) => {
        setSelectedTabIndex(newTabIndex);
    };

    return (
        <div className="table table__wrapper">
            <Tabs
                toggle={handleTabChange}
                selected={selectedTabIndex}
                tabItems={['tabs.all_bets', 'tabs.my_bets']}
            />
            {
                selectedTabIndex === 1 ? <BetListTable /> : <BetHistoryTable />
            }
        </div>
    );
})

export default LeftSideTableContainer;
