import {useMemo} from 'react';

const StarGenerator =({count = 60}) => {
    const stars = useMemo(() => (
        Array.from({length: count}, (_, index) => (
            <span className="single-star" key={index}/>
        ))
    ), [count]);

    return (
        <div className={`animation__stars`}>
                {stars}
        </div>
    );
};

export default StarGenerator;