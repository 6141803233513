import {useDispatch} from "react-redux";
import {updateController} from "../../redux/actions/controller/actions";
import React from "react";
import CloseComponent from "../components/common/CloseComponent/CloseComponent";

const CloseController = React.memo(function ({controllerId, isActive}) {
    const dispatch = useDispatch();
    const handleToggleController = () => {
        dispatch(updateController({
            bettorId: controllerId,
            isActive: !isActive,
            autoBet: false,
            autoCashOut: false,
        }))
    }
    return <CloseComponent handleClose={handleToggleController}/>
})

export default CloseController