import {openModal} from "../../../redux/actions/modal/actions";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

function HeaderButtons() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.user.isMobile);
    const handleOpenModal = (name) => {
        dispatch(openModal({name}))
    }

    return  (!isMobile && <div className="header__button">
        <div className="header__button-item" onClick={() => handleOpenModal('ProvablyFairnessModal')}>
            <span className="header__button-icon" />
            <p className="header__button-text">{t(`header.proof`)}</p>
        </div>
        <div className="header__button-item" onClick={() => handleOpenModal('TermAndConditionsModal')}>
            <span className="header__button-icon"/>
            <p className="header__button-text">{t(`header.rules`)}</p>
        </div>
    </div>)
}

export default HeaderButtons;