import Body from "./Body/Body";
import {useTranslation} from "react-i18next";
function ProvablyFairnessModal() {
    const {t} = useTranslation();
    return <Body>
        <div className="popup__title">{t("modals.proof_modal.title")}</div>
        <div className="popup__paragraph">{t("modals.proof_modal.paragraph1")}</div>
        <div className="popup__paragraph">{t("modals.proof_modal.paragraph2")}
            <a className="popup__link" target="_blank" rel="noopener noreferrer"
               href="https://sha512.online/">https://sha512.online/</a>:
        </div>
        <div className="popup__paragraph">{t("modals.proof_modal.paragraph3")}</div>
        <div className="popup__paragraph">{t("modals.proof_modal.paragraph4")}</div>
        <div className="popup__subtitle">{t("modals.proof_modal.subtitle")}</div>
        <div className="popup__paragraph">{t("modals.proof_modal.paragraph5")}</div>
    </Body>
}

export default ProvablyFairnessModal