import {useTranslation} from "react-i18next";
import useDebounce from "../../../hooks/DebounceClick";
import {useDispatch} from "react-redux";
import {bet, betAccepted} from "../../../redux/actions/controller/actions";
import {gameRoundDemoPlayerBet} from "../../../redux/actions/game/actions";
import {userUpdateBalance} from "../../../redux/actions/user/actions";

function Bet({data}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const debouncedBetOnBox = useDebounce(betOnBox);

    function betOnBox() {
        if (data?.isDemoActive) {
            dispatch(betAccepted({
                bettorId: data.bettorId,
                roundId: data.betRoundId,
                amount: data.amount,
                transactionId: data.bettorId,
            }));
            dispatch(gameRoundDemoPlayerBet({
                table_roundId: data.betRoundId,
                table_transactionId: data.bettorId,
                table_name : "D***o",
                table_bet : data.amount,
                table_odd : null,
                table_win : null,
            }));
            dispatch(userUpdateBalance({balance: parseFloat(data.balance) - parseFloat(data.amount)}))
        } else {
            dispatch(bet({
                bettorId: data.bettorId,
                amount: data.amount,
                isAutoBet: data.autoBet,
            }))
        }
    }

    return <div className="controlItem__right__bet">
        <button className="controlItem__right__bet--btn" onClick={() => debouncedBetOnBox()}>
                    <span className="controlItem__right__bet--btn--text">
                         {t(`control.${data?.next ? 'bet_next_btn' : 'bet_btn'}`)}
                    </span>
        </button>
    </div>
}

export default Bet;