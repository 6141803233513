import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {closeMobileMenu, openModal} from "../../../redux/actions/modal/actions";

function HeaderBurgerMenu() {
    const isMobileMenuOpen = useSelector(state => state.modal.isMobileMenuOpen);
    const isMobileLandscape = useSelector(state => state.user.isMobileLandscape);
    const hasTournament = useSelector(state => state.tournament.hasTournament);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const exitFromGame = () => {
        window.parent.postMessage({action: 'goHome'}, '*')
    }

    const handleOpenModal = (name) => {
        dispatch(openModal({name}))
    }

    const menuItems = [
        {method: () => dispatch(closeMobileMenu()), key: 'mobile_menu.play'},
        {method: () => handleOpenModal('ProvablyFairnessModal'), key: 'mobile_menu.proof'},
        {method: () => handleOpenModal('TermAndConditionsModal'), key: 'mobile_menu.rules'},
        {method: () => handleOpenModal('MobileAllBetsModal'), key: 'mobile_menu.all_bets'},
        {method: () => handleOpenModal('MobileHistoryModal'), key: 'mobile_menu.round_history'},
        {method: () => handleOpenModal('MobileSettingsModal'), key: 'mobile_menu.settings'},
        {method: () => exitFromGame(), key: 'mobile_menu.exit'},
    ]

    return ((isMobileMenuOpen &&  !isMobileLandscape) && <div className="mobile-menu">
            <div className="mobile-menu__wrapper">
                <div className="mobile-menu__top" onClick={() => dispatch(closeMobileMenu())}>
                    <div className="mobile-menu__close--btn"></div>
                    <span className="mobile-menu__close--text">{t("mobile_menu.close")}</span>
                </div>
                <div className="mobile-menu__bottom">
                    <ul className="mobile-menu__list">
                        {
                            hasTournament &&
                            <li className="mobile-menu__list--item"
                                onClick={() => handleOpenModal('MobileTournamentModal')}>
                                {t("mobile_menu.tournament")}
                            </li>
                        }
                        {
                            menuItems?.map((item, index) => {
                                return <li
                                    key={index}
                                    className="mobile-menu__list--item"
                                    onClick={() => item.method()}>
                                    {t(item.key)}
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default HeaderBurgerMenu