import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {openModal} from "../../../../redux/actions/modal/actions";
import Spinner from "../../common/Loaders/Spinner";
import {toLocalFullDate, toLocalTime} from "../../../../utilities/utilities";

const RoundHistoryTable = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const finishedRounds = useSelector(state => state.game.finishedRounds);
    const openHonestyModal = (data = {}) => {
        dispatch(openModal({name: 'HonestyModal', data}))
    }
    const renderRow = (row, index) => {
        return <div key={index} className={`table__body--row  table__body--row-betHistory-${row.table_win ? 'green' : 'red'}`}>
            <div
                className="table__body--row--item  item--date"
                data-full_date={toLocalFullDate(row?.table_date)}
            >
                <span>
                    {toLocalTime(row?.table_date)}
                </span>
            </div>

            <div className={`table__body--row--item item--odd `}>
                {row?.table_odd}
            </div>
            <div className={`table__body--row--item item--honestyIcon`}
                 onClick={() => openHonestyModal({
                     roundId: row?.table_roundId,
                     odd: row?.table_odd,
                     resultHash: row?.table_resultHash,
                     salt: row?.table_salt
                 })}>
                <img loading={'lazy'} src="/img/common/icons/icon-honesty.webp" alt="honesty-icon"/>
            </div>
        </div>
    }

    return (
        <div className="table__content">
            <div className="table__header">
                {
                    ['table_date', 'table_odd', 'table_honesty']?.map((header, index) => {
                        return (
                            <div key={index} className="title">
                                {t(`table.${header}`)}
                            </div>
                        )
                    })
                }
            </div>
            <div className="table__body">
                {
                    finishedRounds?.length ? <div className="table__body--wrapper">
                    {
                        finishedRounds?.map((row, index) => {
                            return renderRow(row, index)
                        })
                    }
                </div> : <Spinner />
                }
            </div>

        </div>
    );

};

export default RoundHistoryTable;
