import LinearLoader from "./LinearLoader";
import {useTranslation} from "react-i18next";
import React from "react";
import {useSelector} from "react-redux";

const LoaderGlobal = React.memo(function ({isActive, notifier}) {
    const {t} = useTranslation();
    const isMobile = useSelector(state => state.user.isMobile)
    return(
        <div className={`global-loader ${!isActive ? 'global-loader__invisible' : ''}` } >
            <div className="global-loader__wrapper">
                <div className="global-loader__img">
                    <img src={`/img/common/loaders/global-loader.svg`} alt="global-loader" style={{width: `${isMobile ? 70 : 30}vw`}}  loading={'lazy'}/>
                    <LinearLoader />
                </div>
                {(notifier.labelFor === 'loader' && notifier.message) && <div className="global-loader__error-msg">{t(`notification.${notifier.message}`)}</div>}
            </div>
        </div>
    )
})
export default LoaderGlobal