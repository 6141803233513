import {useTranslation} from "react-i18next";
import {cancelBet, resetController} from "../../../redux/actions/controller/actions";
import {useDispatch} from "react-redux";
import useDebounce from "../../../hooks/DebounceClick";
import React from "react";
import {gameRoundDemoPlayerResetBetBox} from "../../../redux/actions/game/actions";
import {userUpdateBalance} from "../../../redux/actions/user/actions";

const CancelBtn = React.memo(function ({data}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const debouncedCancel = useDebounce(handleCancelBet);
    function handleCancelBet() {
        if (data?.isDemoActive) {
            dispatch(resetController({
                bettorId: data.bettorId,
                controller: {
                    isActive: true,
                    autoBet: false,
                    autoCashOut: false,
                },
            }))
            dispatch(gameRoundDemoPlayerResetBetBox({
                roundId: data.roundId,
                bettorId: data.bettorId
            }))
            dispatch(userUpdateBalance({balance: parseFloat(data.balance) + parseFloat(data.amount)}))
        } else {
            dispatch(cancelBet({
                bettorId: data.bettorId,
                roundId: data.roundId,
                transactionId: data.transactionId,
            }))
        }
    }

    return <div className="controlItem__right__bet">
        <button className="controlItem__right__bet--btn orange" onClick={() => debouncedCancel()}>
            <span className="controlItem__right__bet--btn--text">{t("control.bet_cancel")}</span>
        </button>
    </div>
})

export default CancelBtn;