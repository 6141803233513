import React from "react";

const Spinner = React.memo(function () {
    return (
        <div className="loader loader__wrapper">
            <div className="loader__content">
                <div className="loader__image">
                    <div className="loader__spinner"></div>
                </div>
            </div>
        </div>
    )
})
export default Spinner