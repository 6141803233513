import {openModal} from "../../../redux/actions/modal/actions";
import {useDispatch, useSelector} from "react-redux";
import React from "react";

const HeaderTournament =React.memo(function () {
    const dispatch = useDispatch();
    const hasTournament = useSelector(state => state.tournament.hasTournament)
    const handleOpenModal = (name) => {
        dispatch(openModal({name}))
    }
    return (hasTournament &&
        <div className="header__user__tournament" onClick={() => handleOpenModal('PromoModal')}>
            <img loading={'lazy'} src="/img/common/icons/tournament-icon.webp" alt='tournament-icon'
                 className="header__user__tournament--icon"/>
        </div>
    )
})

export default HeaderTournament;