import io from "socket.io-client";
import GAME from "../redux/actions/game/types";
import UserSockets from "./userSocket"

let socket = null
export const socketMiddleware = () => (next) => (action) => {
    return next(action);
};

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');
const setupSocket = async (dispatch) => {

    const server = process.env.REACT_APP_WS_URL;

    socket = io(server, {query: {lobby: true, token: token}, transports: ['websocket', 'polling']});

    const setSocketListeners = () => {

        socket.on('connect', () => UserSockets.onConnect(socket, dispatch));

        socket.on(GAME.EVENT, (data) => UserSockets.handleGameSocketEvents(data, dispatch));

        socket.on('disconnect', () => {
            UserSockets.disconnectSocket(dispatch);
        });

        socket.on('connect_error', (err) => {
            UserSockets.onConnectionError(dispatch, err.message);
        })

    };

    return setSocketListeners();
};

export default setupSocket;