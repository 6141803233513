import {useDispatch} from "react-redux";
import {updateController} from "../../redux/actions/controller/actions";
import React from "react";
import Switcher from "../components/common/Switcher/Switcher";
import {useTranslation} from "react-i18next";

const AutoBet = React.memo(function ({controllerId, autoBet}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const handleToggleAutoBet = () => {
        dispatch(updateController({
            bettorId: controllerId,
            autoBet: !autoBet
        }))
    }

    return  <div className="controlItem__left--autoGame">
        <Switcher
            onToggle={handleToggleAutoBet}
            isActive={autoBet}
        />
        <label htmlFor="switch"
               className="controlItem__left--autoGame--label">{t('control.autoplay')}</label>
    </div>
})

export default AutoBet