import HeaderMobile from "../components/mobile/HeaderMobile";
import HeaderDesktop from "../components/desktop/HeaderDesktop";
import React from "react";
import TournamentHeader from "../components/mobile/TournamentHeader";
import HeaderBurgerMenu from "../components/mobile/HeaderBurgerMenu";

const Header = React.memo(function ({isMobile}) {
    return <>
        {
            isMobile ? <>
                <HeaderMobile/>
                <TournamentHeader/>
                <HeaderBurgerMenu/>
            </> : <HeaderDesktop/>
        }
    </>

}, (prevProps, nextProps) => prevProps.isMobile === nextProps.isMobile)
export default Header;