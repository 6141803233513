import React from "react";
import {useTranslation} from 'react-i18next';
import {useDispatch} from "react-redux";
import {updateController} from "../../../../redux/actions/controller/actions";

const NewControllerBox = React.memo(function({isActive, emptyBettorId}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const openSecondController = () => {
        return dispatch(updateController({bettorId: 2, isActive: true}));
    };
    return (
        isActive && <div className="control__add" onClick={openSecondController}>
            <div className="control__add--btn"></div>
            <label className="control__add--text">{t('control.add_text')}</label>
        </div>
    )
})

export default NewControllerBox
