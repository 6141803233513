import {useDispatch} from "react-redux";
import {updateController} from "../../redux/actions/controller/actions";
import NumberStepper from "../components/common/NumberStepper/NumberStepper";
import {useTranslation} from "react-i18next";
import React from "react";
import Switcher from "../components/common/Switcher/Switcher";

const AutoCashOutStepper = React.memo(function ({controllerId, minOdd, maxOdd, cashOutOdd, autoCashOut, disabled}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const handleAutoCashOutChange = (odd) => {
        dispatch(updateController({
            bettorId: controllerId,
            autoCashOutOdd: odd
        }))
    }

    const handleToggleAutoCashOut = () => {
        dispatch(updateController({
            bettorId: controllerId,
            autoCashOut: !autoCashOut
        }))
    }

    return  <div
        className={`controlItem__right__cashOut ${disabled ? 'auto-cashOut-disabled' : ''}`}>
        <Switcher
            onToggle={handleToggleAutoCashOut}
            isActive={autoCashOut}
            direction={'vertical'}
        />
        <div
            className={`controlItem__right__cashOut--amount ${!autoCashOut ? "controlItem__right__cashOut--amount--disabled" : ''}`}>
            <label>{t('control.cashOut')}</label>
            <NumberStepper
                minValue={minOdd}
                maxValue={maxOdd}
                step={0.01}
                inputValue={cashOutOdd}
                handleChange={handleAutoCashOutChange}
                hasDoubleButton={false}
            />
        </div>
    </div>
})

export default AutoCashOutStepper