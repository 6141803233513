const types = {
    SET_INITIAL_DATA: 'setInitialData',
    UPDATE: 'updateController',
    BET_ACCEPTED: 'betAccepted',
    BET: 'bet',
    CANCEL_BET: 'cancelBet',
    CASH_OUT: 'cashOut',
    RESET: 'resetController',
    GET_ROUND_ID: 'getRoundId',
    GET_ROUND_ID_SUCCESS: 'getRoundIdSuccess',
}
export default types;