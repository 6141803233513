import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Spinner from "../../common/Loaders/Spinner";
import {convertAmountByUserRate} from "../../../../utilities/utilities";

const LeaderBoardTable = ({leaderBoard = []}) => {
    const {t} = useTranslation();
    const userId = useSelector(state => state.user.id);
    const rate = useSelector(state => state.user.currencyRate);
    const renderRow = (row, index) => {
        return <div key={index} className={`table__body--row  table__body--row-tournament  table__body--row-tournament--${userId === row?.table_playerId ? 'selected' : ''}`}>
            <div className="table__body--row--item item--tournament">
                <p>{row.table_place || index + 1}</p>
                <p className='flex-grow'>{row?.table_playerId}</p>
            </div>
            <div className="table__body--row--item">
                {row?.table_points || "-"}
            </div>
            <div className={`table__body--row--item ${row?.table_prize ? "item--win" : ""}`}>
                {convertAmountByUserRate(row?.table_prize, rate)}
            </div>
        </div>
    }

    return (
        <div className="table__content">
            <div className="table__header">
                {
                    ['table_playerId', 'table_points', 'table_prize']?.map((header, index) => {
                        return (
                            <div key={index} className="title">
                                {t(`table.${header}`)}
                            </div>
                        )
                    })
                }
            </div>
            <div className="table__body">
                {
                    leaderBoard?.length ? <div className="table__body--wrapper">
                        {
                            leaderBoard?.map((row, index) => {
                                return renderRow(row, index)
                            })
                        }
                    </div> : <Spinner/>
                }
            </div>

        </div>
    );

};

export default LeaderBoardTable;
