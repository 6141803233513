import {useDispatch} from "react-redux";
import {updateController} from "../../redux/actions/controller/actions";
import ButtonsBar from "../components/common/ButtonsBar/ButtonsBar";
import React from "react";

const FixedBetButtons = React.memo(function ({controllerId, minBet, maxBet, amount, disabled}) {
    const dispatch = useDispatch();
    const handleChangeBet = (amount) => {
        dispatch(updateController({
            bettorId: controllerId,
            amount
        }))
    }
    return <ButtonsBar
        handleClick={handleChangeBet}
        currentBet={amount}
        minBet={minBet}
        maxBet={maxBet}
        disabled={disabled && 'controller--disabled'}
    />
})

export default FixedBetButtons