import USER from "../actions/user/types";
import GAME from "../actions/game/types";
import UserSocket from "../../sockets/userSocket";

const initialState = {
    integratorName: 'smartbet',
    id: null,
    lang: 'am',
    currencyCode: null,
    currencyId: null,
    currencyRate: 1,
    balance: 0,
    isMobile: false,
    isMobileLandscape: false,
    userName: '',
    isDemoActive: false,
    isLogin: false
};

const reducer = (state = initialState, action) => {
    const socket = UserSocket.socket

    switch (action.type) {

        case USER.IS_MOBILE:
            return {
                ...state,
                isMobile: action.payload.isMobile,
                isMobileLandscape: action.payload.isMobileLandscape
            };
        case USER.SET_ACCOUNT_DATA:
            return {
                ...state,
                ...action.payload
            };

        case USER.UPDATE_DATA:
            return {
                ...state,
                ...action.payload
            };

        case USER.GET_BALANCE:
            return {
                ...state,
                balance: action.payload.balance
            };

        case USER.DISCONNECT_SOCKET:
            socket.emit(GAME.EVENT, {action: action.type})
            return state

        case USER.TOGGLE_DEMO:
            socket.emit(GAME.EVENT, {action: action.type, payload: action.payload})
            return {
                ...state,
                isDemoActive: action.payload.isDemoActive
            }

        case USER.UPDATE_DEMO_BALANCE:
            return {
                ...state,
                balance: action.payload.balance
            }

        default:
            return state;
    }
};

export default reducer;
