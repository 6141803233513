// eslint-disable

import React, {useEffect, useRef, useState} from "react";

const DropDown = React.memo(function ({icon = "settings", items, onChange})  {
    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef();
    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        if (!isOpen) return
        function handleClickOutside(event) {
            !ref.current?.contains(event.target) && toggleDropdown();
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, isOpen]);

    const onChangeHandler = (value) => {
        onChange && onChange(value);
    }
    return (
        <div className="dropdown dropdown__wrapper" ref={ref}>
            <div
                className={`dropdown__icon dropdown__icon--${icon} ${isOpen ? `dropdown__icon--${icon}-active` : ''}`}
               onClick={toggleDropdown}
            />
            <div className={'setting-icon'}/>
            {isOpen && <div className="dropdown__list">
                {
                    items?.map((item, index) => (
                        <div key={index + "dropdownItem"} className={`dropdown__list--item`}
                             onClick={() => item.onChange ? item.onChange(item.value) : onChangeHandler(item.value)}>{item.component}</div>
                    ))
                }
            </div>
            }
        </div>
    );
})
export default DropDown