import React, {useRef} from "react";

const CopyButton = React.memo(function ({value}) {
    const componentRef = useRef();
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(value);
            toggleClassName();
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    const toggleClassName = () => {
        document.querySelectorAll('.honesty__info-field--btn').forEach(el =>el.classList.remove("honesty__info-field--btn-green"));
        componentRef.current?.classList.add("honesty__info-field--btn-green")
    }
    return <div className="honesty__info-field--btn" ref={componentRef} onClick={() => copyToClipboard()}></div>
})
export default CopyButton