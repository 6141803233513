import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {convertAmountByUserRate} from "../../../utilities/utilities";
import {openModal} from "../../../redux/actions/modal/actions";
import React from "react";

const TournamentHeader = React.memo(function () {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const id = useSelector(state => state.user.id);
    const currencyRate = useSelector(state => state.user.currencyRate);
    const isMobileLandscape = useSelector(state => state.user.isMobileLandscape);
    const hasTournament = useSelector(state => state.tournament.hasTournament);
    const prize = useSelector(state => state.tournament.description.prize);
    const leaderBoard = useSelector(state => state.tournament.leaderBoard);
    const row = leaderBoard?.find(row => row.table_playerId === String(id));

    return (!isMobileLandscape && hasTournament &&
        <div className="tournament__header" onClick={() => dispatch(openModal({name: 'MobileTournamentModal'}))}>
            <div className="tournament__header__center">
                <div className="tournament__header__center__wrapper">
                    <div className="tournament__header__center__item">
                        <span className="tournament__header__center__item--prize">{t('tournament.prize')}</span>
                        <span
                            className="tournament__header__center__item--prize-pool">{convertAmountByUserRate(prize, currencyRate)}</span>
                    </div>
                    <div className="tournament__header__center__item">
                        <span className="tournament__header__center__item--score">{t('tournament.my_score')} </span>
                        <span
                            className="tournament__header__center__item--points">{row?.table_points || 0}</span>
                    </div>
                </div>
                <div className="tournament__header__right">
                    <div className="tournament__header__right__wrapper">
                        <img loading={"lazy"} className="tournament__header__right__icon" src="/img/common/icons/tournament-icon.webp"
                             alt="tournament-icon"/>
                    </div>
                </div>
            </div>

        </div>
    )
})

export default TournamentHeader;
