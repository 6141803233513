import * as game from './game/actions';
import * as tournament from './tournament/actions';
import * as userActions from './user/actions';
import * as controllerActions from './controller/actions';

let combinedActions = {
    ...game,
    ...userActions,
    ...tournament,
    ...controllerActions
}
export default combinedActions