import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";

const Balloon = React.memo((data) => {
    const {
        multiplier,
        sprite,
        state
    } = data;
    const integratorName = useSelector(state => state.user.integratorName);
    const [imgIndex, setIndex] = useState(1);
    const imgIndexDelay = useRef(1)

    useEffect(() => {
        if (state === 'betting') {
            setIndex(1)
        }
        if (state === 'playing') {
            imgIndexDelay.current = imgIndexDelay.current <= 50 ? imgIndexDelay.current + 1 : 1;
            const index = (imgIndexDelay.current % 5) === 0 ? 8 : 9;
            setIndex(index)

        }
        if (state === 'start' || state === 'crashed') {
            setIndex(sprite)
        }
    }, [state, multiplier, sprite]);

    return  (
        Array.from({length: 18}, (_, index) => {
            return <img
                loading={'lazy'}
                key={index + 1}
                src={`/img/${integratorName}/balloon/bl-${index + 1}.webp`}
                className={`animation__balloon-img ${imgIndex === index + 1 ? '' : 'opacity-none'} `}
                alt="Balloon"
            />

        })
    )
}, (prevProps, nextProps) => {
    return prevProps.state === nextProps.state && prevProps.sprite === nextProps.sprite && prevProps.multiplier === nextProps.multiplier
});

export default Balloon;
