import Body from "./Body/Body";
import RightSideTable from "../Tables/RightSideTable/RightSideTable";
import {useSelector} from "react-redux";

function MobileHistoryModal() {
    const isMobile = useSelector(state => state.user.isMobile);
    return (isMobile && <Body>
        <RightSideTable/>
    </Body>)
}

export default MobileHistoryModal