import {useCallback, useEffect, useState} from 'react';
import './randomImages.scss';
import {BackgroundElements} from "../../../constants"

const ImgGenerator = () => {
    const [randomImageAnimating, setRandomImageAnimating] = useState(true);
    const [randomImageIndex, setRandomImageIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(null);

    useEffect(() => {
        setCurrentImage(BackgroundElements[randomImageIndex])
    }, [randomImageIndex]);

    useEffect(() => {
        if (!randomImageAnimating) {
            setRandomImageIndex(prevIndex => {
                return prevIndex + 1 >= BackgroundElements.length ? 0 : prevIndex + 1;
            });
        }
    }, [randomImageAnimating]);


    const handleAnimation = useCallback((isActive) => {
        setRandomImageAnimating(isActive);
    }, []);

    return (
        currentImage && <img
            loading={'lazy'}
            width={`${currentImage.size}%`}
            alt={currentImage.name}
            src={`/img/common/elements/${currentImage.name}`}
            className={`${currentImage.className} `}
            onAnimationEnd={() => handleAnimation(false)}
            onAnimationStart={() => handleAnimation(true)}
        />
    );
};

export default ImgGenerator;