import {useDispatch} from "react-redux";
import {closeModal} from "../../../../redux/actions/modal/actions";
import {useTranslation} from "react-i18next";

function ModalHeader() {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const backToGame = () => {
        dispatch(closeModal())
    }
    return (
        <div className="popup__header">
            <div className=" popup__header__back" onClick={backToGame}>
                <i className="popup__header__back--icon"/>
                <span className="popup__header__back--text">{t("mobile_menu.back_to_menu")}</span>
            </div>
        </div>
    )
}

export default ModalHeader;