import React from 'react';
import {useSelector} from "react-redux";

const Background = React.memo (function({animate}) {
    const integratorName = useSelector(state => state.user.integratorName);
    return <img
        loading={'lazy'}
        src={`/img/${integratorName}/background.webp`}
        alt="Background"
        className={`animation__background-image  ${animate ? 'animate' : 'stop-animate'}`}
    />
});

export default Background;
