import React , {useState, useEffect}from 'react';
import {useDispatch} from "react-redux";
import {gameResetNotification} from "../../../../redux/actions/game/actions";
import {useTranslation} from 'react-i18next';

const Notifier = React.memo(function({status, message}) {
    const {t} = useTranslation();
    const [visible, setVisible] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        let timer = setTimeout(() => {
            setVisible(false);
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div
            className={`notification-common-styles notification-visible 
            ${visible ? '' : 'notification-hidden'}
            ${status === 'success' ? 'notification-success' : 'notification-failed'}
            `}
            onTransitionEnd={()=> dispatch(gameResetNotification())}
        > {t(`notification.${message}`)}</div>
    )
})

export default Notifier
