import HeaderButtons from "./HeaderButtons";
import HeaderLogo from "../common/HeaderLogo/HeaderLogo";
import HeaderUserInfo from "./HeaderUserInfo";

function HeaderDesktop() {
    return <div className="header header__wrapper">
        <HeaderButtons/>
        <HeaderLogo/>
        <HeaderUserInfo/>
    </div>
}

export default HeaderDesktop;