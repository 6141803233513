import Body from "./Body/Body";
import {useSelector} from "react-redux";
function PromoModal() {
    const promo = useSelector(state => state.tournament.description.promo)
    const lang = useSelector(state => state.user.lang);
    const {html, img} = promo[lang];

    return <Body>
        <div className="promo">
            <div className="promo__image">
                {img && <img loading={'lazy'} alt="promo" src={img}/>}
            </div>
            <div className="promo__content" dangerouslySetInnerHTML={{__html: html}}/>
        </div>
    </Body>
}

export default PromoModal